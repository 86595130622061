import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import ExchangeItem from "../components/ExchangeItem/ExchangeItem";

const Exchanges = ({ slice }) => {

    const items = slice.items

    return (
        <SectionLayout sectionName="exchanges mb-5">
            <div className="first-line d-flex ">
                {items.map(item => (
                    <ExchangeItem item={item}/>
                ))}
            </div>
        </SectionLayout>
    )
}

export default Exchanges