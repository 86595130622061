import React from "react";
import SectionLayout from "../layouts/SectionLayout"
// import Image from "../components/Image/Image"
import SectionHeader from "../components/SectionHeader/SectionHeader"
// import { Link } from "gatsby"

const TradingsoftwareContent = ({slice, index}) => {

    const contentData = slice.primary

    return (
        <SectionLayout sectionName={(`${(contentData.heading === null) ? ('exchanges trading') : ('trading-software')} text-center`)}>
            {contentData.heading !== null &&
                <>
                    <SectionHeader title={contentData.heading} />
                    <div className="trading-software__content">
                        <h4 className="content__sub-title">{contentData.title}</h4>
                        <p className="lead-text content1">{contentData.subtitle}</p>
                        <p className="lead-text content2">{contentData.content}</p>
                    </div>
                </>
            }
        </SectionLayout>
    )

}

export default TradingsoftwareContent