import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionHeader from "../components/SectionHeader/SectionHeader";
import VideoComponent from "../components/VideoComponent/VideoComponent";

const VideoSlice = ({slice, index}) => {

    const data = slice.primary
    const videoId = slice.primary.videoid || '6Qk0qn2SOk0'
    const videoTitle = slice.primary.videotitle || 'Why Altrady'

    return (
        <SectionLayout sectionName="exclusive-video">
            <SectionHeader title={data.title} content={data.content}/>
            <div className="video-section">
                <VideoComponent 
                    videoID={videoId}
                    videoTitle={videoTitle}
                />
            </div>
        </SectionLayout>
    )
}

export default VideoSlice