import React from "react";
import { Image } from "../Image/Image";


const ExchangeItem = ({ item }) => {

    return (
        <div className="feature-item">
            <Image src={item.icon} loading={"eager"} fadeIn={false} />
            <div className="feature-item__content-wrapper">
                <p>{item.name}</p>
            </div>
        </div>
    )
}


export default ExchangeItem