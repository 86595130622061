import React from "react";

const VideoComponent = ({ videoID, videoTitle, clsName, ...props }) => {

    return (
        <div className={"video-container " +clsName} >
            <iframe
                 id="videoframe"
                 className={`video ${clsName}`}
                 title={videoTitle}
                 frameborder="0"
                 allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                 scrolling="no" 
                 marginheight="0" 
                 marginwidth="0" 
                 type="text/html" 
                 loading="lazy"
                 srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;left:0;right:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:#fff;text-shadow:0 0 0.3em black}</style>
                 <a href=https://www.youtube-nocookie.com/embed/${videoID}?autoplay=1&modestbranding=1&iv_load_policy=3&theme=light&playsinline=1>
                 <img src=https://i.ytimg.com/vi_webp/${videoID}/sddefault.webp>
                 <span>▶</span>
                 `}
            />
        </div>
    )
}

export default VideoComponent