import React from "react";

const TermsSlice = ({ slice }) => {

    const title = slice.primary.title
    const paragraphs = slice.items

    return (

        <div sectionName="termsSection">
            <div className="layout-container mt-4 mb-4">
                <h3>{title}</h3>
                <div>
                {paragraphs.map(item => (
                    <p>
                        {item.content}
                    </p>
                ))}
                </div>
            </div>
        </div>
    )
}


export default TermsSlice