import React from "react"
import HeroSlice from "../../slices/HeroSlice"
import Exchanges from "../../slices/ExchangesSlice"
import TermsSlice from "../../slices/TermsSlice"
import TradingsoftwareContent from "../../slices/TradingSoftwareContent"
import VideoSlice from "../../slices/VideoSlice"

export const SliceZone = ({ slices, btnValue, clsName }) => {


    const sliceComponents = {
        hero_slice: HeroSlice,
        exchanges_slice: Exchanges,
        terms_content: TermsSlice,
        trading_software_content: TradingsoftwareContent,
        video_section: VideoSlice,
    }

    return slices.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
            return <SliceComponent slice={slice} key={`slice-${index}`} index={index} btnValue={btnValue} clsName={clsName}/>
        }
        return null
    })


}