import React from "react";
import { Image } from "../components/Image/Image";
// import VideoComponent from "../components/VideoComponent/VideoComponent";
import "../style/styles.scss"

const HeroSlice = ({ slice, clsName }) => {
    const content = slice.primary

    return (

        <section id="home" className={`section hero ` + clsName}>
            <div className="layout-container">
                <div className="heroRow">
                    <div className="col-lg-6 col-sm-12 left-part">
                        {/* <div className="logo-container">
                            {content.icon &&
                             <Image src={content.icon} alt={content.alt} loading={"eager"} fadeIn={false} className="logo-icon" />
                            }
                            <p>{content.cqs_title}</p>
                            <p>{content.cqs_subtitle}</p>
                        </div> */}
                        <h1 className="section-title">{content.title}</h1>
                        <p className="section-content">{content.description}</p>
                        <div className="btn-container">
                            {content.btn_secondary &&
                                <a className="btn btn-secondary" href={content.btn_secondary_link}>
                                {content.btn_secondary}
                                </a>
                            }
                            <a
                            href={content.btn_primary_link}
                            className="btn btn-primary "
                            >
                                {content.btn_primary}
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 right-part col-sm-12">
                        <Image src={content.img} alt={content.alt} loading={"eager"} fadeIn={false} />
                        {/* {!content.videoid &&
                        } */}
                        {/* {content.videoid &&
                            <VideoComponent 
                                videoID={content.videoid}
                                videoTitle={content.videotitle}
                                clsName={vdName}
                            />
                        } */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroSlice