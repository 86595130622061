import React from "react";
import Seo from "../components/Seo/Seo";
import PageLayout from "../layouts/PageLayout";
import { graphql } from "gatsby";
import { SliceZone } from "../components/SlizeZone/SliceZone";

const WhatIsAltrady = ({ data }) => {

    if (!data) return null

    const pageContent = data.prismicWhatisaltrady
    const page = pageContent.data || {}

    const metaTitle = page.meta_title
    const metaDescription = page.meta_description
    const metaKeywords = page.meta_keywords
    const clsName = 'crypto-hero'
    
    return (

        <PageLayout>
            <Seo
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeywords}
            />
            <SliceZone slices={page.body} clsName={clsName}/>
        </PageLayout>
    )
}

export default WhatIsAltrady


export const query = graphql`
query WhatisAltradyPage($id: String) {
  prismicWhatisaltrady(id: {eq: $id}) {
    url
    uid
    type
    lang
    id
    data {
      meta_title
      meta_keywords
      meta_description
      flag_page
      body {
        ... on PrismicWhatisaltradyDataBodyHeroSlice {
          id
          slice_label
          slice_type
          primary {
            title
            description
            btn_primary
            btn_primary_link
            videotitle
            videoid
            img {
              alt
              gatsbyImageData
              url
            }
          }
        }
        ... on PrismicWhatisaltradyDataBodyVideoSection {
          id
          slice_type
          slice_label
          primary {
            videotitle
            title
            videoid
            content
          }
        }
      }
    }
  }
}


`